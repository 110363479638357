var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"title grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$_t('Fill in your contact data')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-8",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$_t('Personal details')))]),_c('div',{staticClass:"divider-line"})])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":"","disabled":_vm.submitted},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":_vm.nameRules,"label":_vm.$_t('First name') + ' *',"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":_vm.surnameRules,"label":_vm.$_t('Last name') + ' *',"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('surname')},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":_vm.patientNumberRules,"label":_vm.$_t('NHS number'),"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('patientNumber'),"validate-on-blur":""},model:{value:(_vm.patientNumber),callback:function ($$v) {_vm.patientNumber=$$v},expression:"patientNumber"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.disabledDoB},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$_t('Birthday date') + ' *',"readonly":"","rules":_vm.birthdayRules,"outlined":"","disabled":_vm.disabledInputs.includes('birthday')},model:{value:(_vm.birthdayFormatted),callback:function ($$v) {_vm.birthdayFormatted=$$v},expression:"birthdayFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.birthdayMenu),callback:function ($$v) {_vm.birthdayMenu=$$v},expression:"birthdayMenu"}},[_c('v-date-picker',{ref:"birthdayPicker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1900-01-01","locale":_vm.getLocale(),"first-day-of-week":1},on:{"change":function($event){return _vm.setBirthday()}},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1),_c('v-row',[_c('v-col',{staticClass:"pb-8",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$_t('Address')))]),_c('div',{staticClass:"divider-line"})])],1),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.$_t('Street name'),"rules":[
            function (v) { return !v ||
              (v && v.length <= 100) ||
              _vm.$_t('Street name') +
                ' ' +
                _vm.$_t('must be less than or equal to') +
                ' 100 ' +
                _vm.$_t('characters'); }
          ],"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('streetName')},model:{value:(_vm.streetName),callback:function ($$v) {_vm.streetName=$$v},expression:"streetName"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.$_t('Street number'),"rules":[
                function (v) { return !v ||
                  (v && v.length <= 20) ||
                  _vm.$_t('Street number') +
                    ' ' +
                    _vm.$_t('must be less than or equal to') +
                    ' 20 ' +
                    _vm.$_t('characters'); }
              ],"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('streetNumber')},model:{value:(_vm.streetNumber),callback:function ($$v) {_vm.streetNumber=$$v},expression:"streetNumber"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.$_t('Apt number'),"rules":[
                function (v) { return !v ||
                  (v && v.length <= 20) ||
                  _vm.$_t('Apt number') +
                    ' ' +
                    _vm.$_t('must be less than or equal to') +
                    ' 20 ' +
                    _vm.$_t('characters'); }
              ],"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('aptNumber')},model:{value:(_vm.aptNumber),callback:function ($$v) {_vm.aptNumber=$$v},expression:"aptNumber"}})],1)],1),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.$_t('Zip code'),"rules":[
            function (v) { return !v ||
              (v && v.length <= 20) ||
              _vm.$_t('Zip code') +
                ' ' +
                _vm.$_t('must be less than or equal to') +
                ' 20 ' +
                _vm.$_t('characters'); }
          ],"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('zipCode')},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.$_t('Town'),"rules":[
            function (v) { return !v ||
              (v && v.length <= 50) ||
              _vm.$_t('Town') +
                ' ' +
                _vm.$_t('must be less than or equal to') +
                ' 50 ' +
                _vm.$_t('characters'); }
          ],"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('town')},model:{value:(_vm.town),callback:function ($$v) {_vm.town=$$v},expression:"town"}}),_c('v-row',[_c('v-col',{staticClass:"pb-8",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$_t('Contact details')))]),_c('div',{staticClass:"divider-line"})])],1),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.$_t('Phone number') + ' *',"rules":_vm.phoneNumberRules,"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('phoneNumber'),"validate-on-blur":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":_vm.agreementEmailCheckbox
              ? _vm.$_t('Email address')
              : _vm.$_t('Email address (optional)'),"rules":_vm.emailRules,"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('email'),"validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('p',{staticClass:"mb-4 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$_t("Administrator personal data 1"))+" "),_c('a',{attrs:{"href":"https://www.aptvision.com","target":"_blank"}},[_vm._v(_vm._s(_vm.$_t('administrator personal data 2')))]),_vm._v(". ")]),_c('v-checkbox',{staticClass:"mt-0 mb-6",attrs:{"rules":[function (v) { return !!v || _vm.$_t('Please accept terms and conditions.'); }],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" * "+_vm._s(_vm.$_t('I accept'))+" "),_c('a',{attrs:{"href":"https://www.aptvision.com","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$_t('terms and conditions')))]),_vm._v(". ")])]},proxy:true}]),model:{value:(_vm.termsAndConditionsCheckbox),callback:function ($$v) {_vm.termsAndConditionsCheckbox=$$v},expression:"termsAndConditionsCheckbox"}}),_c('v-btn',{staticClass:"full-width text-none",attrs:{"color":"primary","x-large":"","disabled":!_vm.valid || _vm.submitted},on:{"click":_vm.submitForm}},[(!_vm.submitted)?_c('span',[_vm._v(" "+_vm._s(_vm.$_t('Book Appointment'))+" ")]):_c('span',[_c('v-progress-circular',{attrs:{"size":24,"color":"primary","indeterminate":""}})],1)])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.valid)?_c('v-alert',{attrs:{"color":"red","type":"error"}},[_vm._v(" "+_vm._s(_vm.$_t('Not all mandatory fields have been completed'))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }